import { checkNoAds } from "./ad-blocker";

/**
 * Loads the Raptive code when certain conditions are met.
 */
export function raptiveLoader() {
    // The `marker` looks for a `noads` class, which turns off ads
    // for certain pages.
    //
    // The code they sent by email was this:
    // <!-- Raptive Head Tag Manual -->
    // <script data-no-optimize="1" data-cfasync="false">
    // (function(w, d) {
    //  w.adthrive = w.adthrive || {};
    //  w.adthrive.cmd = w.
    //  adthrive.cmd || [];
    //  w.adthrive.plugin = 'adthrive-ads-manual';
    //  w.adthrive.host = 'ads.adthrive.com';var s = d.createElement('script');
    //  s.async = true;
    //  s.referrerpolicy='no-referrer-when-downgrade';
    //  s.src = 'https://' + w.adthrive.host + '/sites/655b69eb3e8d2079ca947f4e/ads.min.js?referrer=' + w.encodeURIComponent(w.location.href) + '&cb=' + (Math.floor(Math.random() * 100) + 1);
    //  var n = d.getElementsByTagName('script')[0];
    //  n.parentNode.insertBefore(s, n);
    // })(window, document);
    // </script>
    // <!-- End of Raptive Head Tag -->
    //
    // If we don't wait for `DOMContentLoaded` to check for the `noads`
    // selector, then the ads load where they aren't supposed to.

    const noads = checkNoAds();

    if (noads) {
        console.log("ads=0");
    } else {
        loadMainRaptiveScript();
        loadRaptiveAdRecoveryScripts();
        console.log("ads=1");
    }
}

type CreateScriptOptions = {
    nooptimize?: boolean;
    async?: boolean;
    id?: string;
};
function createScript(code: string, options: CreateScriptOptions = {}) {
    const script = document.createElement("script");

    // Always turn off Cloudflare's Rocket Loader.
    script.dataset.cfasync = "false";

    if (options.nooptimize) {
        script.dataset.noptimize = "1";
    }
    if (options.async) {
        script.async = true;
    }
    if (options.id) {
        script.id = options.id;
    }
    script.innerText = code;
    document.head.appendChild(script);
}

function loadMainRaptiveScript() {
    const raptiveScript = `
        (function (w, d) {
            w.adthrive = w.adthrive || {};
            w.adthrive.cmd = w.adthrive.cmd || [];
            w.adthrive.plugin = "adthrive-ads-manual";
            w.adthrive.host = "ads.adthrive.com";
            var s = d.createElement("script");
            s.async = true;
            s.referrerpolicy = "no-referrer-when-downgrade";
            s.src =
                "https://" +
                w.adthrive.host +
                "/sites/655b69eb3e8d2079ca947f4e/ads.min.js?referrer=" +
                w.encodeURIComponent(w.location.href) +
                "&cb=" +
                (Math.floor(Math.random() * 100) + 1);
            var n = d.getElementsByTagName("script")[0];
            n.parentNode.insertBefore(s, n);
        })(window, document);
    `;

    createScript(raptiveScript, { nooptimize: true });
}

function loadRaptiveAdRecoveryScripts() {
    const script1 = `
        !(function () {
        "use strict";
        !(function (e) {
            if (-1 === e.cookie.indexOf("__adblocker")) {
            e.cookie = "__adblocker=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
            var t = new XMLHttpRequest();
            t.open("GET", "https://www.npttech.com/advertising.js", !0),
                (t.onreadystatechange = function () {
                if (XMLHttpRequest.DONE === t.readyState)
                    if (200 === t.status) {
                    var n = e.createElement("script");
                    (n.innerHTML = t.responseText),
                        e.getElementsByTagName("head")[0].appendChild(n);
                    } else {
                    var a = new Date();
                    a.setTime(a.getTime() + 3e5),
                        (e.cookie =
                        "__adblocker=true; expires=" + a.toUTCString() + "; path=/");
                    }
                }),
                t.send();
            }
        })(document);
        })();
    `;

    const script2 = `
        !(function () {
        "use strict";
        var e;
        (e = document),
            (function () {
            var t, n;
            function r() {
                var t = e.createElement("script");
                (t.src = "https://cafemedia-com.videoplayerhub.com/galleryplayer.js"),
                e.head.appendChild(t);
            }
            function a() {
                var t = e.cookie.match("(^|[^;]+)\\s*__adblocker\\s*=\\s*([^;]+)");
                return t && t.pop();
            }
            function c() {
                clearInterval(n);
            }
            return {
                init: function () {
                var e;
                "true" === (t = a())
                    ? r()
                    : ((e = 0),
                    (n = setInterval(function () {
                        (100 !== e && "false" !== t) || c(),
                        "true" === t && (r(), c()),
                        (t = a()),
                        e++;
                    }, 50)));
                },
            };
            })().init();
        })();
    `;

    createScript(script1, { id: "recovery-script-1", async: true });
    createScript(script2, { id: "recovery-script-2", async: true });
}
